import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/services/rest-api.service';

@Component({
  selector: 'app-about-ed-tech-terms',
  templateUrl: './about-ed-tech-terms.page.html',
  styleUrls: ['./about-ed-tech-terms.page.scss'],
})
export class AboutEdTechTermsPage implements OnInit {

  urlPath: String;
  content: String;
  image: String;
  ettLogoUrl:string;
  copyrightLogoUrl:string;


  constructor(private router: Router,
    private restApi:RestApiService) {
    this.urlPath = this.router.url;
  }

  ngOnInit() {

    if (this.urlPath == "/home/about-edtech") {
      this.content = "<b>EdTechTerms</b> is your quick source for definitions and references when a term is new or fuzzy. Access terms during a challenging presentation. Find the right term when writing. Follow a discussion like an expert.<hr><b>EdDataTerms</b> is ESP’s own category of education technology terms.  These terms have been assembled over many years as we have assisted our clients to have design, develop, and acquire solutions. ";
      this.restApi.getProperty("ETTLogo").subscribe(data => {
        this.ettLogoUrl = data.propertyValue;
        this.image=this.ettLogoUrl;
      })
    }

    if (this.urlPath == "/home/about-esp") {
      this.content = "ESP provides expert consultation, design, and solution development for education agencies and their vendors." ;
      this.restApi.getProperty("copyrightLogoUrl").subscribe(data => {
        this.copyrightLogoUrl = data.propertyValue;
        this.image=this.copyrightLogoUrl;
      })
    }
  }

  

}
